<template>
  <div class="list-container">
    <ul class="animated-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="list-item"
        :ref="(el) => (listItems[index] = el)"
        :class="{ visible: visibleItems[index] }"
        v-html="item"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AnimatedList',
  props: ['isMobile'],
  data() {
    return {
      items: [
        '<b>Native Apps</b>: Perfekte Performance durch Swift & Kotlin.',
        '<b>Hybrid Apps</b>: Schneller Cross-Plattform-Einsatz (z.B. Flutter)',
        '<b>Moderne Webs</b>: Effiziente Websites mit Vue, React oder WordPress',
        '<b>Direkt</b>: Als Ein-Mann-Firma erhältst Du schnelle Abstimmungen & flexible Lösungen',
      ],
      listItems: [],
      visibleItems: {},
      observers: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setupObservers();
    });
  },
  beforeUnmount() {
    // Cleanup observers
    this.observers.forEach((observer) => observer.disconnect());
  },
  methods: {
    setupObservers() {
      this.listItems.forEach((item, index) => {
        if (!item) return;

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                // console.log('inter');
                this.visibleItems[index] = true;
                // Disconnect after animation is triggered
                observer.disconnect();
              }
            });
          },
          {
            threshold: 0.2, // 20% of the item must be visible
            rootMargin: '0px 0px -100px 0px',
          },
        );

        observer.observe(item);
        this.observers.push(observer);
      });
    },
  },
};
</script>

<style scoped>
.list-container {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.animated-list {
  list-style: none;
  padding: 0;
  margin: 20px auto;
  max-width: 800px;
  text-align: left;
  font-size: 18px;
}

.list-item {
  background-image: linear-gradient(200deg, rgb(20, 20, 20), rgb(40, 40, 40));
  color: var(--bcd-gray);
  margin: 20px 0;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateX(400px);
  transition: all 1s ease-out;
  border: 1px solid rgb(220, 220, 220, 0.4);
}

.list-item.visible {
  opacity: 1;
  transform: translateX(0);
}

.list-item:nth-child(1) {
  transition-delay: 0.1s;
}
.list-item:nth-child(2) {
  transition-delay: 0.3s;
}
.list-item:nth-child(3) {
  transition-delay: 0.5s;
}
.list-item:nth-child(4) {
  transition-delay: 0.7s;
}
.list-item:nth-child(5) {
  transition-delay: 0.9s;
}
.list-item:nth-child(6) {
  transition-delay: 1.1s;
}

@media (max-width: 768px) {
  .animated-list {
    width: 100%;
  }
  .list-item {
    transform: translateX(200px);
  }
}
</style>
