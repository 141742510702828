<template>
  <div class="card-wrapper">

    <div class="card">
      <div class="card-header">
        <span class="card-icon">{{ icon }}</span>
        <span class="card-title">{{ title }}</span>
      </div>
      <p class="card-desc">{{ desc }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  desc: {
    type: String,
    required: true,
  },
});

const icon = ref(props.icon);
const title = ref(props.title);
const desc = ref(props.desc);
</script>

<style scoped>
.card-wrapper {
  display: flex;
  position: relative;
  border-radius: 8px;
  transition: all .5s ease-in-out;
}

.card {
  background-image: linear-gradient(rgb(20, 20, 20), rgb(39, 39, 39));
  border-radius: 8px;
  padding: 15px;
  justify-self: center;
  /* transform-origin: center center; */
  will-change: transform;
  transform: translateZ(0);
}

.card-wrapper.active {
  transform: scale(1.2);
}

.card-wrapper::before,
.card-wrapper::after {
  content: '';
  position: absolute;
  inset: -0.1rem;
  z-index: -1;
  background: conic-gradient(
    from var(--gradient-angle),
    var(--clr-3),
    var(--clr-4),
    var(--clr-5),
    var(--clr-4),
    var(--clr-3)
  );
  border-radius: inherit;
  /* animation: rotation 20s linear infinite; */
}

.card-wrapper::after {
  filter: blur(0.5rem);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 20px;
  padding-top: 20px;
}

.card-icon {
  font-size: 24px;
  color: #2c3e50;
}

.card-title {
  font-size: 20px;
  color: var(--bcd-gray);
  font-weight: 500;
}

.card-desc {
  text-align: left;
  padding: 22px;
  font-weight: 300;
  color: var(--bcd-gray);
}
</style>
