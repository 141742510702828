<template>
  <Header-view :activePage="activePage" :headerHeight="headerHeight" />
  <div class="text-container">
    <h1>Impressum</h1>

    <h2>Angaben gemäß § 5 TMG</h2>

    <p>
      best cellar design <br />
      Benjamin Heer <br />
      Kurzenmoor 38 <br />
      25370 Seester <br />
      Deutschland
    </p>

    <h2>Kontakt:</h2>
    <p>
      Telefon: +49 160 6127316 <br />
      E-Mail: b.heer@bestcellardesign.de
    </p>

    <h2>Umsatzsteuer-ID:</h2>
    <p>USt-ID: DE 326975071</p>

    <h2>Haftungsausschluss</h2>

    <h3>Haftung für Inhalte</h3>
    <p>
      Die Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Dennoch können wir
      für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Informationen keine
      Gewähr übernehmen. Als Diensteanbieter im Sinne des § 7 Abs.1 TMG sind wir für eigene Inhalte
      auf diesen Seiten verantwortlich. Wir weisen jedoch darauf hin, dass wir gemäß §§ 8 bis 10 TMG
      nicht verpflichtet sind, übermittelte oder gespeicherte fremde Informationen zu überwachen
      oder nach Anhaltspunkten für rechtswidrige Tätigkeiten zu suchen. Verpflichtungen zur
      Entfernung oder Sperrung von Inhalten bleiben hiervon unberührt. Eine Haftung setzt den
      Zeitpunkt voraus, ab dem uns eine konkrete Rechtsverletzung bekannt wird – in diesem Fall
      werden wir die betreffenden Inhalte unverzüglich entfernen.
    </p>

    <h3>Haftung für Links</h3>
    <p>
      Unsere Webseite enthält Verknüpfungen zu externen Webseiten, auf deren Inhalte wir keinen
      Einfluss haben. Für diese fremden Inhalte können wir daher keinerlei Haftung übernehmen. Der
      jeweilige Anbieter oder Betreiber der verlinkten Seiten ist allein verantwortlich für deren
      Inhalt. Zwar erfolgte zum Zeitpunkt der Verlinkung eine Prüfung auf mögliche Rechtsverstöße,
      jedoch können wir keine dauerhafte inhaltliche Kontrolle garantieren. Sollten uns
      Rechtsverstöße auf verlinkten Seiten bekannt werden, entfernen wir die entsprechenden Links
      umgehend.
    </p>

    <h3>Urheberrecht</h3>

    <p>
      Die auf dieser Webseite veröffentlichten Inhalte und Werke, insbesondere Texte, Grafiken,
      Logos, Bilder, Videos und Software, unterliegen dem Urheberrecht und anderen Schutzgesetzen.
      Jede Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
      Grenzen des Urheberrechts bedarf der ausdrücklichen schriftlichen Zustimmung des jeweiligen
      Urhebers bzw. Rechteinhabers. Downloads und Kopien dieser Webseite sind ausschließlich für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit Inhalte Dritter genutzt werden,
      erfolgt die Kennzeichnung des jeweiligen Urhebers. Bei berechtigten Hinweisen auf
      Urheberrechtsverletzungen werden wir die betreffenden Inhalte umgehend entfernen.
    </p>

    <h3>Datenschutz</h3>

    <p>
      Die Nutzung unserer Webseite ist grundsätzlich möglich, ohne dass personenbezogene Daten
      angegeben werden müssen. Falls Sie uns dennoch, etwa über ein Kontaktformular,
      personenbezogene Daten wie Name, Anschrift oder E-Mail-Adresse freiwillig mitteilen, werden
      diese ausschließlich zum Zwecke der Bearbeitung Ihrer Anfrage verwendet. Eine Weitergabe an
      Dritte erfolgt nicht, sofern Sie nicht ausdrücklich eingewilligt haben. Weitere Informationen
      zum Umgang mit Ihren Daten und zu Ihren Rechten finden Sie in unserer ausführlichen
      Datenschutzerklärung.
    </p>
    <br />
    <p>Stand: 01.01.2025</p>
  </div>
  <FooterView />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import HeaderView from '../components/HeaderView.vue';
import FooterView from '../components/FooterView.vue';

const { appContext } = getCurrentInstance();
const bus = appContext.config.globalProperties.$bus;
const router = useRouter();
const activePage = ref(0);
const headerHeight = ref(160);

function checkIfMobile() {
  if (window.innerWidth < 768) {
    headerHeight.value = 100;
  }
}

bus.$on('logoTapped', () => {
  router.push({ path: '/', hash: '#home' });
});

onMounted(() => {
  checkIfMobile();
  const header = document.querySelector('.header');
  const sep = document.querySelector('.sep');
  if (header && sep) {
    header.classList.add('background');
    sep.classList.add('fade-in');
  }
});
</script>

<style scoped>
.text-container {
  max-width: 1000px;
  margin-top: 180px;
  margin-left: auto;
  margin-right: auto;
  /* margin: auto; */
  color: var(--bcd-gray);
  padding: 20px;
}

h1,
h2,
h3,
p {
  color: var(--bcd-gray);
}

@media (max-width: 768px) {
  .text-container {
    margin-top: 100px;
  }
}
</style>
