<template>
  <div class="accordion">
    <!-- <h2 class="section-sub-title">Wissenswertes {{ title }}</h2> -->
    <div v-for="(item, index) in accordionItems" :key="index" class="accordion-item">
      <button
        class="accordion-header"
        @click="toggleItem(index)"
        :class="{ active: activeItems[index] }"
      >
        <div class="icon-wrapper">
          <div class="icon">
            <div class="horizontal-line"></div>
            <div class="vertical-line" :class="{ collapsed: activeItems[index] }"></div>
          </div>
        </div>
        <h3 class="accordion-title">{{ item.title }}</h3>
      </button>
      <div
        class="accordion-content"
        :class="{ active: activeItems[index] }"
        :style="{ maxHeight: activeItems[index] ? contentHeights[index] + 'px' : '0' }"
      >
        <div class="content-inner" :id="'content-' + index" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  // title: {
  //   type: String,
  //   required: true,
  // },
  accordionItemsDictProb: {
    type: Array,
    required: true,
  },
});

// const title = ref(props.title);
const accordionItems = ref(props.accordionItemsDictProb);

const activeItems = ref({});
const contentHeights = ref({});

const updateContentHeight = (index) => {
  const contentRef = document.querySelector(`#content-${index}`);
  if (contentRef) {
    contentHeights.value[index] = contentRef.scrollHeight;
  }
};

const updateAllHeights = () => {
  accordionItems.value.forEach((_, index) => {
    updateContentHeight(index);
  });
};

const toggleItem = (index) => {
  activeItems.value[index] = !activeItems.value[index];
  accordionItems.value.forEach((_, index2) => {
    if (index !== index2) {
      activeItems.value[index2] = false;
      updateContentHeight(index2);
    }
  });
  updateContentHeight(index);
};

onMounted(() => {
  accordionItems.value.forEach((_, index) => {
    updateContentHeight(index);
  });
  window.addEventListener('resize', updateAllHeights);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateAllHeights);
});
</script>

<style scoped>
.accordion {
  max-width: 800px;
  margin: 0px auto;
  /* background: white; */
  /* border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.accordion-item {
  background-image: linear-gradient(rgb(30, 30, 30), rgb(40, 40, 40));
  /* border: 1px solid var(--bcd-blue); */
  margin: 20px 0 20px 0;
  /* padding: 2px; */
  border-radius: 8px;
  /* background: white; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-header {
  all: unset;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 90%;
}

.accordion-header:hover {
  /* background-color: #f8f8f8; */
}

.accordion-header.active {
  /* background-color: #f5f5f5; */
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  position: relative;
  width: 16px;
  height: 16px;
}

.horizontal-line,
.vertical-line {
  position: absolute;
  background-color: var(--bcd-orange);
  border-radius: 2px;
  transition: all 0.3s ease;
}

.horizontal-line {
  width: 100%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.vertical-line {
  width: 2px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(0);
}

.vertical-line.collapsed {
  transform: translateX(-50%) rotate(90deg);
  opacity: 0;
}

.accordion-title {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--bcd-gray);
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0;
  text-align: start;
  /* color: var(--bcd-gray); */
}

.content-inner {
  padding: 0 20px 20px 59px;
  color: var(--bcd-gray);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .accordion {
    /* margin: 0px; */
  }
  /* .accordion-item {
  margin: 20px 0 20px 0;
} */
}
</style>
