<template>
  <div class="card-grid-wrapper" :class="{ 'is-sticky': isSticky }">
    <div class="card-grid" ref="cardGrid">
      <Card
        v-for="(card, index) in cardItems"
        :key="index"
        :icon="card.icon"
        :title="card.title"
        :desc="card.desc"
        :class="{ active: activeCard === index && isFullyVisible }"
      />
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import Card from './CardViewLight.vue';

const props = defineProps({
  cardItems: {
    type: Array,
    required: true,
  },
  shouldHandleWheel: {
    type: Boolean,
    required: true,
  },
});

const cardItems = ref(props.cardItems);
const cardGrid = ref(null);

function setupIntersectionObserver(element) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        element.classList.add('visible');
      } else {
        element.classList.remove('visible');
      }
    });
  }, options);

  observer.observe(element);
}

const isComplete = ref(false);
const isFullyVisible = ref(false);
const stickyPosition = ref({ top: 0, left: 0 });
const isSticky = ref(false);
const scrollStartPosition = ref(0);
const wheelEventCount = ref(0);
const animationProgress = ref(0);
const activeCard = ref(0);

const handleWheel = (event) => {
  if (!cardGrid.value || isComplete.value) return;

  const wrapperRect = cardGrid.value.getBoundingClientRect();
  const offsetBottom = 100;
  // console.log(event);
  // console.log(wrapperRect.top);
  // console.log(wrapperRect.bottom);
  // console.log(window.innerHeight);
  // console.log(window.innerHeight - offsetBottom);
  // console.log('----------------------');

  if (wrapperRect.top < 100) {
    isComplete.value = true;
    return;
  }

  if (
    wrapperRect.bottom <= window.innerHeight - offsetBottom
    // && wrapperRect.top < 600
    // && wrapperRect.top > 400
  ) {
    if (!isFullyVisible.value) {
      isFullyVisible.value = true;
      stickyPosition.value = {
        bottom: window.innerHeight - wrapperRect.bottom,
        left: wrapperRect.left,
      };
      isSticky.value = true;
      scrollStartPosition.value = window.pageYOffset;
      document.body.style.overflow = 'hidden';
    }
  }

  if (isFullyVisible.value && !isComplete.value) {
    event.preventDefault();

    if (wheelEventCount.value < 0) {
      // console.log('back');
      wheelEventCount.value = 0;
      isSticky.value = false;
      isFullyVisible.value = false;
      window.scrollTo({
        top: scrollStartPosition.value - 100,
        behavior: 'smooth',
      });
      // window.removeEventListener('wheel', handleWheel);
      document.body.style.overflow = '';
      return;
    }
    const delta = event.deltaY > 0 ? 1 : -1;
    wheelEventCount.value += delta;
    // console.log(wheelEventCount.value);

    // Reduzierte Scroll-Geschwindigkeit
    const progressPerEvent = 0.06; // Langsamere Animation
    animationProgress.value = Math.max(0, Math.min(6, wheelEventCount.value * progressPerEvent));

    const tabIndex = Math.floor(animationProgress.value);
    if (tabIndex !== activeCard.value && tabIndex < 6) {
      activeCard.value = tabIndex;
      // console.group(activeCard.value);
    }

    // Animation beenden
    if (animationProgress.value >= 6 - 0.1) {
      isComplete.value = true;
      isSticky.value = false;
      isFullyVisible.value = false;
      document.body.style.overflow = '';
      window.scrollTo({
        top: scrollStartPosition.value + wrapperRect.height,
        behavior: 'smooth',
      });
    }
  }
};

onMounted(() => {
  if (props.shouldHandleWheel) {
    window.addEventListener('wheel', handleWheel, { passive: false });
  }
  // getCards();
  nextTick(() => {
    if (cardGrid.value) {
      setupIntersectionObserver(cardGrid.value);
    }
  });
});

onUnmounted(() => {
  if (props.shouldHandleWheel) {
    window.removeEventListener('wheel', handleWheel);
  }
  document.body.style.overflow = '';
});
</script>

<style scoped>
.card-grid-wrapper {
  position: relative;
  /* height: 100%; */
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 50px;
  column-gap: 100px;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
  transition: opacity 1s ease;
  opacity: 0;
}

.card-grid.visible {
  opacity: 1;
}

.card-grid.is-sticky {
  position: fixed;
  bottom: v-bind('stickyPosition.bottom + "px"');
  left: v-bind('stickyPosition.left + "px"');
  max-width: 1000px;
  height: 600px;
  opacity: 1;
}

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}
</style>
