import { createApp } from 'vue';
import './assets/css/global.css';
import App from './AppAlt.vue';
import $bus from './utils/Events';
import router from './router';

// if ('scrollRestoration' in window.history) {
//   window.history.scrollRestoration = 'manual';
// }
const app = createApp(App);
app.use(router);

app.config.globalProperties.$bus = $bus;

app.mount('#vue-app');
