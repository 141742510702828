<template>
  <div class="timeline-container" ref="timelineContainer">
    <div class="timeline-line" ref="timelineLine"></div>
    <div
      v-for="(item, index) in timelineItems"
      :key="index"
      :ref="(el) => (timelineRefs[index] = el)"
      :class="['item-container', getSide(index), { visible: visibleItems[index] }]"
    >
      <TimelineItemView :item="item" :is-visible="visibleItems[index]" :side="getSide(index)" />
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import TimelineItemView from './TimelineItemView.vue';

const props = defineProps({
  timelineItems: {
    type: Array,
    required: true,
  },
});

const observers = ref([]);
const visibleItems = ref({});
const timelineRefs = ref([]);
const timelineItemsRef = ref(props.timelineItems);
const timelineLine = ref(null);
const timelineContainer = ref(null);

function getSide(index) {
  if (index === timelineItemsRef.value.length - 1) {
    return 'center';
  }
  return index % 2 === 0 ? 'left' : 'right';
}

function createObserver(element, index) {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          visibleItems.value[index] = true;
          observer.disconnect();
        }
      });
    },
    {
      threshold: 0.3,
      rootMargin: '0px 0px -200px 0px',
    },
  );

  observer.observe(element);
  observers.value.push(observer);
}

function setupObservers() {
  //   console.log(timelineRefs.value);
  timelineRefs.value.forEach((item, index) => {
    if (!item) return;
    createObserver(item, index);
  });
}

onMounted(() => {
  nextTick(() => {
    // console.log(timelineItems);
    setupObservers();
    if (timelineRefs.value.length > 0 && timelineContainer.value && timelineLine.value) {
      const containerRect = timelineContainer.value.getBoundingClientRect();
      const lastItem = timelineRefs.value[timelineRefs.value.length - 1];
      const lastItemRect = lastItem.getBoundingClientRect();
      const newHeight = lastItemRect.top - containerRect.top;
      timelineLine.value.style.height = `${newHeight}px`;
    }
  });
});

onUnmounted(() => {
  observers.value.forEach((observer) => observer.disconnect());
});
</script>

<style scoped>
.timeline-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 20px; */
  /* overflow: hidden; */
  padding-top: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.timeline-line {
  position: absolute;
  left: calc(50% - 1px);
  width: 2px;
  /* height: calc(100% + 250px); */
  top: 0px;
  background-color: var(--bcd-blue);
}

.item-container {
  position: relative;
  width: 50%;
  height: auto;
  opacity: 0;
  transition: all 0.8s ease;
  margin-bottom: 40px;
}

.item-container.left {
  margin-right: auto;
}

.item-container.right {
  margin-left: auto;
}

.item-container.center {
  margin: auto;
}

.item-container.visible {
  opacity: 1;
}

@media (max-width: 768px) {
  .timeline-container {
    padding-top: 50px;
  margin-top: 0px;
  overflow: hidden;
  /* margin-bottom: 0px; */
}
  .timeline-line {
    /* opacity: 0; */
    visibility: hidden;
  }
  .item-container {
    width: 100%;
  }
}
</style>
