<template>
  <a
    :class="activeClasses"
    :href="`#${title}`"
    :title="`This link goes to the ${title} page`"
    @click.prevent="$bus.$emit('navbarLinkActivated', {title, index})"
    >{{ title.toUpperCase() }}</a
  >
</template>

<script>
export default {
  props: ['title', 'isActive', 'index'],
  computed: {
    activeClasses() {
      return {
        active: this.isActive,
        notActive: !this.isActive,
      };
    },
  },
};
</script>

<style scoped>

.active {
  background: -webkit-linear-gradient(#ffffff, rgb(71,131,151));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: 18px;
  transition: all 1.5s ease; */
}

.notActive {
  background: -webkit-linear-gradient(#eee, #444444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* transform: scale(1);
  transition: all .5s ease; */
}
</style>
