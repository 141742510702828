<template>
  <div class="comparison-container" ref="comparisonContainer">
    <div class="comparison-content">
      <div class="comparison-box-wrapper native" :class="{ animate: isVisible }">
        <div class="comparison-box">
          <h2>Native App-Entwicklung</h2>
          <ul class="comparison-list">
            <li v-for="(item, index) in nativePoints" :key="index" :class="item.type">
              <span class="icon" :class="item.type">{{ item.type === 'pro' ? '+' : '−' }}</span>
              {{ item.text }}
            </li>
          </ul>
        </div>
      </div>

      <div class="vs-badge" :class="{ animate: isVisible }">VS</div>

      <div class="comparison-box-wrapper hybrid" :class="{ animate: isVisible }">
        <div class="comparison-box">
          <h2>Hybride App-Entwicklung</h2>
          <ul class="comparison-list">
            <li v-for="(item, index) in hybridPoints" :key="index" :class="item.type">
              <span class="icon" :class="item.type">{{ item.type === 'pro' ? '+' : '-' }}</span>
              {{ item.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isVisible = ref(false);
const comparisonContainer = ref(null);
let observer;

const nativePoints = [
  { type: 'pro', text: 'Beste Performance und Geschwindigkeit' },
  { type: 'pro', text: 'Voller Zugriff auf Plattform-spezifische Features' },
  { type: 'pro', text: 'Optimale UI/UX nach Plattform-Guidelines' },
  { type: 'pro', text: 'Höhere App-Store-Akzeptanzrate' },
  { type: 'con', text: 'Separate Codebases für iOS und Android' },
  { type: 'con', text: 'Höhere Entwicklungskosten' },
  { type: 'con', text: 'Längere Entwicklungszeit' },
  { type: 'con', text: 'Mehr Wartungsaufwand durch multiple Codebases' },
];

const hybridPoints = [
  { type: 'pro', text: 'Eine Codebase für alle Plattformen' },
  { type: 'pro', text: 'Schnellere Entwicklung' },
  { type: 'pro', text: 'Kostengünstigere Entwicklung' },
  { type: 'pro', text: 'Einfachere Wartung' },
  { type: 'con', text: 'Eingeschränkte Performance' },
  { type: 'con', text: 'Limitierter Zugriff auf native Features' },
  { type: 'con', text: 'Kompromisse bei UI/UX' },
  { type: 'con', text: 'Größere App-Größe durch Framework-Overhead' },
];

onMounted(() => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        isVisible.value = true;
      } else {
        // isVisible.value = false;
      }
    });
  }, options);

  observer.observe(comparisonContainer.value);
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<style scoped>
.comparison-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  overflow: hidden;
  /* display: flex; */
}

.comparison-content {
  display: flex;
  /* justify-content: space-between; */
  align-items: stretch;
  gap: 30px;
}

.comparison-box-wrapper {
  display: flex;
  /* flex: 1; */
  position: relative;
  border-radius: 16px;
  transition: all .5s ease-in-out;
}

.comparison-box {
  /* flex: 1; */
  background-image: linear-gradient(180deg, rgb(12, 12, 12), rgb(26, 26, 26));
  border-radius: 16px;
  padding: 40px;
  /* height: 600px; */
}

.comparison-box-wrapper.native {
  will-change: transform;
  transform: translateX(-200%);
}

.comparison-box-wrapper.hybrid {
  will-change: transform;
  transform: translateX(200%);
}

.comparison-box-wrapper.native.animate {
  animation: boxBounce 1.2s cubic-bezier(0.36, 0, 0.66, 1) 0.5s forwards;
}

.comparison-box-wrapper.hybrid.animate {
  animation: boxBounceReverse 1.2s cubic-bezier(0.36, 0, 0.66, 1) 0.5s forwards;
}

.comparison-box-wrapper::before,
.comparison-box-wrapper::after {
  content: '';
  position: absolute;
  inset: -0.1rem;
  z-index: -1;
  background: conic-gradient(
    from var(--gradient-angle),
    var(--clr-3),
    var(--clr-4),
    var(--clr-5),
    var(--clr-4),
    var(--clr-3)
  );
  border-radius: inherit;
  /* animation: rotation 20s linear infinite; */
}

.comparison-box-wrapper::after {
  filter: blur(0.3rem);
}

@keyframes boxBounce {
  0% {
    will-change: transform;
    transform: translateX(-200%);
  }
  60% {
    transform: translateX(5%);
  }
  80% {
    transform: translateX(-3%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes boxBounceReverse {
  0% {
    transform: translateX(200%);
  }
  60% {
    transform: translateX(-5%);
  }
  80% {
    transform: translateX(3%);
  }
  100% {
    transform: translateX(0);
  }
}

.vs-badge {
  background-image: linear-gradient(270deg, #121212, #383838);
  border: 1px solid var(--bcd-blue);
  color: var(--bcd-gray);
  font-weight: bold;
  padding: 15px 25px;
  border-radius: 50%;
  font-size: 18px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
  opacity: 0;
  transform: scale(0);
  align-self: center;
}

.vs-badge.animate {
  animation: vsBadgeAppear 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards;
}

@keyframes vsBadgeAppear {
  0% {
    opacity: 0;
    transform: scale(0) rotate(-180deg);
  }
  60% {
    transform: scale(1.2) rotate(10deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

.comparison-box h2 {
  text-align: center;
  color: var(--bcd-orange);
  margin-bottom: 55px;
  font-size: 23px;
  font-weight: 400;
}

.comparison-list {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--bcd-gray);
  text-align: start;
}

.comparison-list li {
  display: flex;
  margin-bottom: 30px;
  font-size: 18px;
  gap: 30px;
  font-weight: 200;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  font-weight: bold;
  font-size: 18px;
  flex-shrink: 0;
}

.pro .icon {
  background: #4caf50;
  color: #fbfffb;
}

.con .icon {
  background: #f44336;
  color: #ffffff;
}

@media (max-width: 768px) {
  .comparison-content {
    flex-direction: column;
  }

  .comparison-box {
    width: 100%;
  }

  .vs-badge {
    margin: 20px 0;
  }

  .comparison-title {
    font-size: 2em;
  }

  .comparison-box.animate {
    animation: boxBounceVertical 1.2s cubic-bezier(0.36, 0, 0.66, 1) 0.5s forwards;
  }

  .comparison-box.hybrid.animate {
    animation: boxBounceVerticalReverse 1.2s cubic-bezier(0.36, 0, 0.66, 1) 0.5s forwards;
  }

  @keyframes boxBounceVertical {
    0% {
      transform: translateY(-200%);
    }
    60% {
      transform: translateY(5%);
    }
    80% {
      transform: translateY(-3%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes boxBounceVerticalReverse {
    0% {
      transform: translateY(200%);
    }
    60% {
      transform: translateY(-5%);
    }
    80% {
      transform: translateY(3%);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@media (max-width: 768px) {
  .comparison-content {
  gap: 20px;
}
.comparison-container {
  padding: 10px;
}
.comparison-box {
  padding: 30px;
}
.comparison-box h2 {
  margin-bottom: 55px;
  font-size: 22px;
}
}
</style>
