<template>
  <div class="hero-card-container">
    <div class="hero-card-content">
      <h2 class="hero-card-title">{{ title }}</h2>
      <p class="hero-card-desc">{{ desc }}</p>
    </div>
    <div class="image-background">
      <img :src="imagePath" alt="" />
      <div class="image-overlay"></div>
    </div>
    <button class="button-overlay" @click.prevent="$bus.$emit('heroSliderTapped', link)"></button>
  </div>
</template>

<script>
export default {
  name: 'HeroSliderCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.hero-card-container {
  border-radius: 25px;
}

.hero-card-container::before,
.hero-card-container::after {
  content: '';
  position: absolute;
  inset: -0.1rem;
  z-index: -1;
  background: conic-gradient(
    from var(--gradient-angle),
    var(--clr-3),
    var(--clr-4),
    var(--clr-5),
    var(--clr-4),
    var(--clr-3)
  );
  border-radius: inherit;
  animation: rotation 20s linear infinite;
}

.hero-card-container::after {
  filter: blur(0.3rem);
}

.image-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  border-radius: 25px;
}

.image-background img {
  opacity: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  filter: grayscale();
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(rgba(0, 13, 16, 0.906), rgba(9, 23, 28, 0.919));
  border-radius: 25px;
}

.hero-card-content {
  position: absolute;
  z-index: 1000;
  padding: 40px;
  bottom: 0;
}

.hero-card-title {
  background: -webkit-linear-gradient(270deg, #d5d5d5, #797979);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: start;
  font-size: 40px;
}

.hero-card-desc {
  background: -webkit-linear-gradient(270deg, #d5d5d5, #797979);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: start;
  font-size: 24px;
}

.button-overlay {
  all: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 1000;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hero-card-title {
    font-size: 26px;
  }

  .hero-card-desc {
    font-size: 18px;
  }
}
</style>
