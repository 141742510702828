<template>
  <div class="mobile-menu-container">
    <img src="/assets/bcd_logo/bcd_logo_very_small.webp" alt="" loading="lazy" />
    <div class="button-container">
      <button class="link-button" @click="$bus.$emit('mobileMenuLinkTapped', 'home')">
        <span>HOME</span>
      </button>
      <button class="link-button" @click="$bus.$emit('mobileMenuLinkTapped', 'about')">
        <span>ABOUT</span>
      </button>
      <button class="link-button" @click="$bus.$emit('mobileMenuLinkTapped', 'app')">
        <span>APP</span>
      </button>
      <button class="link-button" @click="$bus.$emit('mobileMenuLinkTapped', 'web')">
        <span>WEB</span>
      </button>
    </div>
    <hr />
    <div class="button-container">
      <button class="link-button" @click="$bus.$emit('mobileMenuLinkTapped', 'contact')">
        <span>KONTAKT</span>
      </button>
    </div>
    <hr />
    <div class="button-container">
      <button class="link-button" @click="$bus.$emit('mobileMenuLinkTapped', 'imprint')">
        <span>IMPRESSUM</span>
      </button>
      <button class="link-button" @click="$bus.$emit('mobileMenuLinkTapped', 'privacy')">
        <span>DATENSCHUTZ</span>
      </button>
    </div>
    <!-- <ul>
            <li><a href="#home">HOME</a></li>
            <li><a href="#about">ABOUT</a></li>
            <li><a href="#app">APP</a></li>
            <li><a href="#web">WEB</a></li>
        </ul> -->
  </div>
</template>

<script setup></script>

<style scoped>
.mobile-menu-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  padding: 20px;
  width: 240px;
  height: 100%;
  border-right: 1px solid var(--border-color);
  /* background-color: aqua; */
  /* opacity: 0; */
  z-index: 100;
  transform: translateX(-300px);
  transition: all 0.3s ease-in;
  background-image: linear-gradient(90deg, rgb(0, 0, 0), rgb(18, 18, 18));
}

.mobile-menu-container.open {
  transform: translateX(0px);
  /* opacity: 1; */
}

.mobile-menu-container li,
a {
  text-decoration: none;
  list-style: none;
  color: var(--bcd-gray);
}

.mobile-menu-container img {
  width: 100px;
  align-self: center;
  margin-bottom: 20px;
}

.button-container {
  margin: 20px 0;
  /* margin-top: 40px; */
  padding-left: 20px;
  position: relative;
  width: auto;
  height: auto;
  /* background-color: aquamarine; */
}

.link-button {
  all: unset;
  color: var(--bcd-gray);
  width: 100%;
  height: 40px;
}

hr {
  width: calc(100% - 40px);
  /* height: 0.5px; */
  border: 0.5px solid var(--bcd-gray);
}
</style>
