<template>
  <div class="content">
    <div :style="{ height: isMobile ? '240px' : '900px' }"></div>
    <SectionView
      id="home"
      titleTop="Digitale"
      titleBottom="Lösungen"
      :barWidth="isMobile ? 200 : 420"
      :isMobile="isMobile"
    >
      <div :style="{ height: isMobile ? '30px' : '80px' }"></div>
      <h2 class="section-sub-title">
        Du suchst einen starken Partner für die Umsetzung Deiner Ideen?
      </h2>
      <h3 class="section-sub-sub-title">Dann bist Du hier genau richtig!</h3>
      <p class="text-block-home">
        Ich unterstütze Dich bei <b>App- und Webprojekten</b>, von der <b>Planung</b> bis zum
        <b>Launch</b>. Dank schlanker Prozesse verbinde ich die
        <b>Flexibilität eines Freelancers</b> mit dem
        <b> Qualitätsanspruch einer größeren Agentur </b>.
      </p>
      <div :style="{ height: isMobile ? '0px' : '30px' }"></div>
      <HeroSlider />
      <div :style="{ height: isMobile ? '20px' : '50px' }"></div>
      <h2 class="section-sub-title">Meine Stärken:</h2>
      <div style="height: 0px"></div>
      <AnimatedList :isMobile="isMobile" />
      <div :style="{ height: isMobile ? '50px' : '80px' }"></div>
      <h2 class="section-sub-title">Jetzt Starten</h2>
      <p class="text-block-home">
        Du möchtest Dein nächstes <b>digitales Projekt</b> umsetzen?<br /><b>Kontaktiere mich</b>
        und wir finden gemeinsam die beste Lösung.<br /><br />Ein <b>kurzes Formular</b> oder
        <b>direkter Mail-Klick</b>
        reichen aus, um loszulegen!
      </p>
      <div style="height: 40px"></div>
      <button class="cta-button" @click="$bus.$emit('contactTapped')">
        Jetzt Kontakt aufnehmen
      </button>
    </SectionView>

    <SectionView id="about" titleTop="Mein" titleBottom="Profil" :barWidth="isMobile ? 120 : 240">
      <div v-if="timelineItemsLoaded">
        <Timeline :timeline-items="timelineItems" />
      </div>
      <p class="text-block-home">
        Bist Du <b>bereit</b> für den nächsten Schritt? Dann lass uns <b>unverbindlich</b> ins
        Gespräch kommen – natürlich <b>kostenlos</b> und <b>ohne jede Verpflichtung</b>.<br /><br />
        <b>Gemeinsam</b> finden wir heraus, wie ich Dich <b> am besten unterstützen </b> kann.
      </p>
      <div style="height: 40px"></div>
      <button class="cta-button" @click="$bus.$emit('contactTapped')">Jetzt Anfrage stellen</button>
    </SectionView>

    <SectionView id="app" titleTop="App" titleBottom="Entwicklung" :barWidth="isMobile ? 170 : 350">
      <div v-if="imageSetsLoaded">
        <HorizontalSliderView :images="appImageSet" />
      </div>
      <div :style="{ height: isMobile ? '70px' : '100px' }"></div>
      <p class="text-block-home">
        Eine App von der <b>reinen Idee</b> bis zur <b>Veröffentlichung in den Stores</b> zu
        bringen, ist <b>komplexer</b>, als viele glauben. <b>Grafikdesign</b>,
        <b>Code-Architektur</b>, <b>Datensicherheit</b> und <b>Store-Richtlinien</b> – all das
        erfordert <b>Erfahrung</b> und <b>Know-how</b>. Auch <b>moderne KI-Lösungen</b> können nur
        Teilaspekte abdecken: Wer <b> nicht tief im Thema </b> steckt, wird schnell
        <b>an Grenzen stoßen</b>.<br /><br />
        Umso <b>wichtiger</b> ist es, einen <b>klaren Prozess</b> zu haben und auf
        <b> professionelle Unterstützung </b> zu setzen.
      </p>
      <div style="height: 100px"></div>
      <h2 class="section-sub-title">Vom Anfang bis zum Ende</h2>
      <h3
        style="
          font-size: 26px;
          font-weight: 400;
          max-width: 800px;
          text-align: center;
          margin: auto;
        "
      >
        Ein strukturelles Vorgehen ist essentiell für eine professionelle Abwicklung und für Dich
        als Kunden ein unschätzbarer Vorteil!
      </h3>
      <div style="height: 80px"></div>
      <div v-if="cardItemsLoaded">
        <CardGrid :cardItems="appCardItems" :should-handle-wheel="false" />
      </div>
      <div :style="{ height: isMobile ? '100px' : '200px' }"></div>
      <h2 class="section-sub-title">Native vs. hybride App-Entwicklung</h2>
      <p class="text-block-home">
        Bei <b>nativer Entwicklung</b> (z.B. Swift für iOS, Kotlin für Android) schöpfe ich aus dem
        Vollen: <b>beste Performance</b>, <b>volle Kontrolle</b> über Gerätefunktionen und ein
        <b>nahtloses Nutzererlebnis</b>. <b>Hybride Apps</b> via Flutter bieten hingegen
        <b>schnelles Cross-Plattform-Deployment</b> und <b> reduzieren Entwicklungsaufwand </b>. Die
        Vorteile nativer Apps sind vor allem Geschwindigkeit und tiefgreifende OS-Integration,
        während Hybrid-Apps mit kürzeren Entwicklungszyklen und einfacherem Wartungsaufwand punkten.
        <br /><br />Welche <b>Lösung</b> passt, hängt vom Projektziel, Budget und Zeitrahmen ab –
        <b>gemeinsam finden wir das Optimum</b>.
      </p>
      <div style="height: 40px"></div>
      <ComparisonView />
      <div style="height: 40px"></div>
      <p class="text-block-home">
        Bist Du <b>bereit</b> für den nächsten Schritt? Dann lass uns <b>unverbindlich</b> ins
        Gespräch kommen – natürlich <b>kostenlos</b> und <b>ohne jede Verpflichtung</b>.<br /><br />
        <b>Gemeinsam</b> finden wir heraus, wie ich Dich <b> am besten unterstützen </b> kann.
      </p>
      <div style="height: 80px"></div>
      <button class="cta-button" @click="$bus.$emit('contactTapped')">
        Jetzt Anfrage für App-Entwicklung stellen
      </button>
      <div :style="{ height: isMobile ? '50px' : '140px' }"></div>
      <div v-if="!isMobile">
        <h2 class="section-sub-title">Meine bisherigen Projekte</h2>
        <p class="text-block-home">
          Ein Blick in meine bisherigen Projekte zeigt Dir, wie individuelle Lösungen in der Praxis
          aussehen. Hier findest Du neben technischen Details auch Stimmen zufriedener Kunden –
          damit Du Dir ein klares Bild von meiner Arbeitsweise und den erzielten Ergebnissen machen
          kannst.
        </p>
        <div style="height: 20px"></div>
        <div v-if="testimonialsLoaded">
          <TestimonialsViewAlt :testimonial-items="appTestimonials" />
        </div>
        <div :style="{ height: isMobile ? '50px' : '140px' }"></div>
      </div>
      <div v-if="accordionItemsLoaded">
        <h2 class="section-sub-title">App-Entwicklung wissenswertes</h2>
        <AccordionView :accordionItemsDictProb="appAccordionItems" />
      </div>
    </SectionView>

    <SectionView id="web" titleTop="Web" titleBottom="Entwicklung" :barWidth="isMobile ? 170 : 350">
      <div v-if="imageSetsLoaded">
        <HorizontalSliderView :images="webImageSet" />
      </div>
      <div :style="{ height: isMobile ? '70px' : '100px' }"></div>
      <p class="text-block-home">
        Heutzutage gibt es unzählige <b>Baukastensysteme</b> und <b>KI-gestützte Tools</b>, die den
        Eindruck vermitteln, eine Website ließe sich <b>im Handumdrehen zusammenklicken</b>. Doch
        wer <b> langfristig erfolgreich </b> sein will, merkt schnell: Nur mit
        <b>professioneller Planung</b>, <b>sauberem Code</b> und
        <b>einer zielgerichteten Strategie</b> wird aus einer „Seite“ eine richtig gute
        Online-Präsenz. Ob SEO-Optimierung, Datenschutzrichtlinien, DSGVO-konformes Impressum oder
        gut durchdachtes Design – es gibt <b>viele Stellschrauben</b>, an denen gedreht werden muss.
        <br /><br />Daher ist ein <b>erfahrener Partner</b> an Deiner Seite <b>unverzichtbar</b>,
        wenn Deine Website nicht nur hübsch aussehen, sondern <b>nachhaltig überzeugen</b> soll.
      </p>
      <div style="height: 100px"></div>
      <h2 class="section-sub-title">Vom Anfang bis zum Ende</h2>
      <h3
        style="
          font-size: 26px;
          font-weight: 400;
          max-width: 800px;
          text-align: center;
          margin: auto;
        "
      >
        Ein strukturelles Vorgehen ist essentiell für eine professionelle Abwicklung und für Dich
        als Kunden ein unschätzbarer Vorteil!
      </h3>
      <div style="height: 80px"></div>
      <div v-if="cardItemsLoaded">
        <CardGrid :cardItems="webCardItems" :should-handle-wheel="false" />
      </div>
      <div :style="{ height: isMobile ? '100px' : '200px' }"></div>
      <p class="text-block-home">
        Bist Du <b>bereit</b> für den nächsten Schritt? Dann lass uns <b>unverbindlich</b> ins
        Gespräch kommen – natürlich <b>kostenlos</b> und <b>ohne jede Verpflichtung</b>.<br /><br />
        <b>Gemeinsam</b> finden wir heraus, wie ich Dich <b> am besten unterstützen </b> kann.
      </p>
      <div style="height: 80px"></div>
      <button class="cta-button" @click="$bus.$emit('contactTapped')">
        Jetzt Anfrage für Web-Entwicklung stellen
      </button>
      <div :style="{ height: isMobile ? '50px' : '140px' }"></div>
      <div v-if="accordionItemsLoaded">
        <h2 class="section-sub-title">App-Entwicklung wissenswertes</h2>
        <AccordionView :accordionItemsDictProb="webAccordionItems" />
      </div>
    </SectionView>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  // computed,
} from 'vue';
import SectionView from './SectionView.vue';
import HeroSlider from './HeroSlider.vue';
import HorizontalSliderView from './HorizontalSliderView.vue';
import AnimatedList from './AnimatedList.vue';
import Timeline from './TimelineAlt.vue';
import AccordionView from './AccordionView.vue';
import ComparisonView from './ComparisonView.vue';
import CardGrid from './CardGrid.vue';
// import CardView from './CardView.vue';
// import TestimonialsView from './TestimonialsView.vue';
import TestimonialsViewAlt from './TestimonialsViewAlt.vue';

defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
});

let timelineItems = ref([]);
let appAccordionItems = ref([]);
let appImageSet = ref([]);
let appCardItems = ref([]);
let appTestimonials = ref([]);
let webAccordionItems = ref([]);
let webImageSet = ref([]);
let webCardItems = ref([]);
const timelineItemsLoaded = ref(false);
const accordionItemsLoaded = ref(false);
const imageSetsLoaded = ref(false);
const cardItemsLoaded = ref(false);
const testimonialsLoaded = ref(false);

async function getAccordionItems() {
  try {
    const res = await fetch('/json/accordion-items.json');
    const data = await res.json();
    appAccordionItems = data.app;
    webAccordionItems = data.web;
    accordionItemsLoaded.value = true;
  } catch (error) {
    console.error('Error fetch accordion items: ', error);
  }
}

async function getImageSets() {
  try {
    const res = await fetch('/json/slider-images.json');
    const data = await res.json();
    appImageSet = data.app;
    webImageSet = data.web;
    imageSetsLoaded.value = true;
  } catch (error) {
    console.error('Error fetch slider images: ', error);
  }
}

async function getCardItems() {
  try {
    const res = await fetch('/json/step-by-step-app.json');
    const data = await res.json();
    // console.log(data);
    appCardItems = data.app;
    webCardItems = data.web;
    cardItemsLoaded.value = true;
  } catch (error) {
    console.error('Error fetch step by step app: ', error);
  }
}

async function getTestimonials() {
  try {
    const res = await fetch('/json/app_testimonials.json');
    const data = await res.json();
    // console.log(data);
    appTestimonials = data.app;
    testimonialsLoaded.value = true;
  } catch (error) {
    console.error('Error fetch testimonials app: ', error);
  }
}

async function getTimelineItems() {
  try {
    const res = await fetch('/json/timeline-items.json');
    const data = await res.json();
    // console.log(data);
    timelineItems = data;
    timelineItemsLoaded.value = true;
  } catch (error) {
    console.error('Error fetch testimonials app: ', error);
  }
}

onMounted(() => {
  getAccordionItems();
  getImageSets();
  getCardItems();
  getTestimonials();
  getTimelineItems();
});
</script>

<style scoped>
.content p {
  color: var(--bcd-gray);
  /* text-align: center; */
  margin: auto;
}

.content h1,
h2,
h3 {
  background: -webkit-linear-gradient(45deg, #eee, #444444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .section-title h2,
h3 {
  background: -webkit-linear-gradient(45deg, #eee, #444444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.section-sub-title {
  text-align: center;
  margin-bottom: 60px;
  font-size: 2.3em;
  font-weight: 500;
  background: -webkit-linear-gradient(45deg, #eee, #444444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-sub-sub-title {
  font-size: 26px;
  margin-bottom: 50px;
}

.cta-button {
  border: none;
  font-size: 20px;
  color: white;
  padding: 12px 26px;
  background-color: var(--bcd-blue);
  border-radius: 10px;
  margin: 14px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.cta-button:hover {
  background-color: var(--bcd-orange);
}

.text-container {
  max-width: 1000px;
  margin: auto;
  color: var(--bcd-gray);
  padding: 50px 0;
}

.text-block-home {
  max-width: var(--text-block-max-width);
  font-size: 20px;
  font-weight: 300;
}

.text-block-home b {
  font-weight: 500;
}

@media (max-width: 768px) {
  .section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .text-block-home {
    font-size: 18px;
  }

  .cta-button {
    font-size: 18px;
  }
}
</style>
