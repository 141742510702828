<template>
  <div class="sep"></div>
  <footer class="footer">
    <div class="column left">
      <!-- <h1 class="title gradient">BCD</h1> -->
      <img src="/assets/bcd_logo/bcd_logo_very_small.webp" alt="" loading="lazy"/>
      <h2 class="sub-title">best cellar design - Ihr Partner für Softwareentwicklung</h2>
      <span class="description">
        Wir bieten individuelle Lösungen in der App- und Webentwicklung sowie im Frontend- und
        Backend-Bereich – spezialisiert auf die Bedürfnisse kleiner und mittelständischer
        Unternehmen. Modern, effizient und zuverlässig.
      </span>
    </div>
    <div class="column middle">
      <h1 class="title gradient">KONTAKT</h1>
      <div style="height: 20px"></div>
      <div class="link"><span>best cellar design</span></div>
      <div class="link"><a href="tel:+491606127316">+49 160 612 73 16</a></div>
    </div>
    <div class="column right">
      <h1 class="title gradient">RECHTLICHES</h1>
      <div style="height: 20px"></div>
      <button class="button-link" @click="linkTapped('imprint')">
        <span>Impressum</span>
      </button>
      <button class="button-link" @click="linkTapped('privacy')">
        <span>Datenschutz</span>
      </button>
      <!-- <div class="link"><a href="/imprint">Impressum</a></div>
      <div class="link"><a href="/privacy">Datenschutz</a></div> -->
    </div>
  </footer>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

function linkTapped(site) {
  // console.log(site);
  router.push(`/${site}`);
}
</script>

<style scoped>
.footer {
  display: flex;
  /* height: auto; */
  /* width: 100%; */
  max-width: 1120px;
  /* width: 1120px; */
  margin: 0 auto;
  padding: 20px 0 100px 0;
  flex-direction: row;
  box-sizing: border-box;
  /* gap: 40px */
}

.footer * {
  box-sizing: border-box;
}

.column {
  /* flex: 50%; */
  float: left;
}

.left {
  flex: 0 0 60%;
  /* width: 60%; */
  padding: 20px 80px 20px 20px;
  /* background-color: green; */
}

.left img {
  /* padding: 20px 0 20px 0; */
  width: 200px;
}

.middle {
  flex: 0 0 25%;
  /* width: 25%; */
  padding: 20px;
  /* background-color: darkred; */
}

.right {
  flex: 0 0 15%;
  /* width: 15%; */
  display: flex;
  padding: 20px;
  flex-direction: column;
  /* background-color: turquoise; */
}

.sep {
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
  opacity: 1;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.sub-title {
  font-size: 17px;
  font-weight: 400;
  color: var(--bcd-gray);
}

.description {
  font-size: 14px;
  font-weight: 200;
  color: var(--bcd-gray);
  padding-bottom: 40px;
  text-align: left;
}

.gradient {
  background: -webkit-linear-gradient(45deg, #a7a7a7, #050505);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.link {
  /* all: unset; */
  padding: 10px 0 10px 0;
  /* margin: 20px 0 20px 0; */
}

.button-link {
  all: unset;
  padding: 10px 0 10px 0;
  cursor: pointer;
}

.link a,
span {
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  color: var(--bcd-gray);
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
}
</style>
