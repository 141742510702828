<template>
  <div class="menu-container">
    <div class="contact-button">
      <button class="side-menu-text-button2" @click="$bus.$emit('menuTapped')">
        <span>- Kontakt -</span>
      </button>
    </div>
    <!-- <button class="side-menu-text-button" @click="$bus.$emit('menuTapped')">
      <span>- Kontakt -</span>
    </button> -->
    <div class="inner-container">
      <!-- <button class="menu-logo-button" @click="$bus.$emit('menuTapped')">
        <img src="/assets/bcd_logo/logo_klein.png" alt="Logo" />
      </button> -->
      <div class="contact-form-container">
        <h2 style="font-size: 22px">Ich freue mich auf Deine Anfrage!</h2>
        <form id="contactForm" @submit.prevent="sendForm">
          <div class="form-group">
            <label for="name"
              >Name:
              <input type="text" id="name" name="name" v-model="formData.name" required />
            </label>
          </div>
          <div class="form-group">
            <label for="email"
              >E-Mail:
              <input type="email" id="email" name="email" v-model="formData.email" required />
            </label>
          </div>
          <div class="form-group">
            <label for="message"
              >Nachricht:
              <textarea id="message" name="message" v-model="formData.message" required></textarea>
            </label>
          </div>
          <div style="display: none">
            <label for="nothing"
              >Bitte nicht ausfüllen!
              <input v-model="hpfield" name="nothing" id="nothing" />
            </label>
          </div>
          <button type="submit">Nachricht senden</button>
        </form>
      </div>
      <div class="phone-info">
        <p>Oder ruf mich gerne an / schreib mir bei WhatsApp:</p>
        <p>
          <a
            href="tel:+4916061274316"
            style="
              text-decoration: none;
              color: var(--bcd-gray);
              font-size: 18px;
              padding-bottom: 5px;
            "
            >Mobil: +49 160 6127316</a
          >
        </p>
        <p>
          <a
            href="mailto:info@bestcellardesign.de"
            style="text-decoration: none; color: var(--bcd-gray); font-size: 18px"
            >Email: info@bestcellardesign.de</a
          >
        </p>
        <div style="height: 10px"></div>
        <p style="font-size: 14px">
          In der Regel werde ich Dir bei schriftlichen Anfragen innerhalb von 2 Werktagen antworten!
        </p>
      </div>
    </div>
  </div>

  <div v-if="successMessage" class="success-msg">
    {{ successMessage }}
  </div>
  <div v-if="errorMessage" class="error-msg">
    {{ errorMessage }}
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';

const formData = ref({
  name: '',
  email: '',
  message: '',
});
const hpfield = ref('');

const successMessage = ref('');
const errorMessage = ref('');

const { appContext } = getCurrentInstance();
const bus = appContext.config.globalProperties.$bus;

const apiUrl = 'https://0xt2lqq2e6.execute-api.eu-north-1.amazonaws.com/prod/ses-send-email';

async function sendForm() {
  bus.$emit('blurTapped');

  if (hpfield.value.trim() !== '') {
    alert('Bot-Verdacht!');
    return;
  }

  successMessage.value = '';
  errorMessage.value = '';

  try {
    const bodyData = {
      name: formData.value.name,
      email: formData.value.email,
      message: formData.value.message,
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      throw new Error(errorResponse || 'Unbekannter Fehler vom Server');
    }

    await response.json();
    successMessage.value = 'Nachricht wurde erfolgreich gesendet!';
    formData.value = { name: '', email: '', message: '' };
  } catch (err) {
    console.error('SendForm error:', err);
    errorMessage.value = 'Fehler beim Senden. Bitte versuche es erneut.';
  }
}
</script>

<style scoped>
.menu-container {
  position: fixed;
  height: 850px;
  width: 0px;
  top: calc(50% - 425px);
  z-index: 100;
  border-radius: 0 20px 20px 0;
  transform: translateX(-100px);
  transition: all 0.3s ease-in;
  /* background-color: #3498db;
    background: #eee; */
  background-image: linear-gradient(270deg, #121212, #000);
  border: 1px solid var(--border-color);
}

/* .menu-container::before,
.menu-container::after {
  content: '';
  position: absolute;
  inset: -0.1rem;
  z-index: -1;
  background: conic-gradient(
      from var(--gradient-angle),
      var(--clr-3),
      var(--clr-4),
      var(--clr-5),
      var(--clr-4),
      var(--clr-3));
  border-radius: inherit;
  animation: rotation 20s linear infinite;
}

.menu-container::after {
  filter: blur(.5rem);
} */

.menu-container h2,
h3,
label,
p {
  color: var(--bcd-gray);
}

.menu-container.open {
  width: 500px;
}

.menu-container.visible {
  transform: translateX(-2px);
}

.contact-button {
  position: absolute;
  top: calc(50% - 140px);
  right: -51px;
  width: 50px;
  height: 280px;
  background-color: #121212;
  border-radius: 0 30px 30px 0;
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.side-menu-text-button2 {
  all: unset;
  transform: rotate(-90deg);
  position: absolute;
  /* right: -270px; */
  left: calc((-280px / 2) + (50px / 2));
  top: calc(50% - (50px / 2));
  width: 280px;
  height: 50px;
  text-align: center;
  /* background-color: rgba(0, 255, 255, 0.238); */
  /* letter-spacing: 5px; */
}

.side-menu-text-button2 span {
  font-size: 28px;
  background: -webkit-linear-gradient(45deg, #eee, #444444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-logo-button {
  all: unset;
  display: flex;
  margin: 5px auto;
  padding-top: 10px;
  transition: all 1.3s ease-in;
}

.menu-logo-button img {
  width: 40px;
  object-fit: scale-down;
  transition: all 0.5s ease;
}

.menu-logo-button.open img {
  width: 100px;
  padding-top: 10px;
  transition: all 0.5s ease;
}

.side-menu-text-button {
  all: unset;
  transform: rotate(-90deg);
  position: absolute;
  right: -270px;
  top: 46%;
  width: 600px;
  text-align: center;
  letter-spacing: 5px;
}

.side-menu-text-button span {
  font-size: 36px;
  background: -webkit-linear-gradient(45deg, #eee, #444444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.inner-container {
  position: absolute;
  top: 60px;
  right: 40px;
  overflow: hidden;
  width: 420px;
  height: 750px;
}

.contact-form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  /* display: block; */
  /* margin-bottom: 25px; */
  /* color: #ffffff; */
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  background-color: #2c2c2c;
  border: 1px solid #4c4c4c;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3498db;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

button[type='submit'] {
  /* position: absolute; */
  left: 50%;
  width: 200px;
  padding: 10px;
  background-color: var(--bcd-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button[type='submit']:hover {
  background-color: var(--bcd-orange);
}

.phone-info {
  color: #eee;
  padding: 20px;
}
</style>
