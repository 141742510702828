<template>
  <section class="section" :id="id">
    <!-- <div class="section-title-container">
      <h1>
        <span class="text-top">{{ titleTop.toUpperCase() }}</span>
        <div class="separator"></div>
        <span class="text-bottom">{{ titleBottom.toUpperCase() }}</span>
      </h1>
    </div> -->
    <div class="section-title">
      <h1>
        <span class="section-title-top">{{ titleTop.toUpperCase() }}</span>
        <div class="title-separator"
        :style="{ width: barWidth + 'px' }"></div>
        <span class="section-title-bottom">{{ titleBottom.toUpperCase() }}</span>
      </h1>
    </div>
    <!-- <div style="height: 50px"></div> -->
    <slot></slot>
    <!-- <div :style="{ height: isMobile ? '100px' : '200px' }"></div> -->
  </section>
</template>

<script>
export default {
  props: {
    id: String,
    titleTop: String,
    titleBottom: String,
    barWidth: Number,
    isMobile: Boolean,
  },
};
</script>

<style scoped>

.section {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 200px;
}

.section h1,
h2,
h3 {
  background: -webkit-linear-gradient(45deg, #eee, #444444);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title-container {
  /* width: 400px;
  background-color: aqua;
  text-align: center;
  align-self: center;
  margin: 0 auto;
  font-size: large; */
  container-type: inline-size; /* oder size, wenn du Höhe/Breite checken willst */
  /* container-name: myContainer; */ /* optional bei mehreren Containern */
  /* width: 80%; */
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  outline: 1px dashed #aaa;
}

.text-top {
  font-size: 20cqw;
  font-weight: 800;
  white-space: nowrap;
}

.text-bottom {
  font-size: 16cqw;
  font-weight: 200;
  white-space: nowrap;
}

.separator {
  width: 90%;
  background-color: var(--bcd-blue);
  height: 5px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.section-title {
  /* animation: fadeIn 2s; */
  text-align: center;
  scale: 0.8;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: 250px 500px;
}

@keyframes fade-in {
  to {
    scale: 1;
    opacity: 1;
  }
}

/* .section-title h1 {
  font-size: 120px;
  font-weight: 800;
  margin: 0;
} */

.section-title-top {
  /* padding: 0; */
  font-size: 120px;
  font-weight: 800;
  /* margin: 0; */
  /* font-size: clamp(4.5rem, 9vw, 7.5rem); */
  /* margin: 0 0 0rem; */
  /* margin-bottom: 2rem; */
}

.title-separator {
  background-color: var(--bcd-blue);
  height: 5px;
  margin: auto;
  /* margin-top: 20px;
  margin-bottom: 30px; */
  margin-top: 1rem;
  margin-bottom: 2rem;
  /* width: 80%; */
  /* margin: 0 auto 1.5rem; */
}

.section-title-bottom {
  /* padding: 0; */
  font-size: 80px;
  font-weight: 200;
  /* margin: 0; */
  /* font-size: clamp(3rem, 9vw, 5rem); */
  /* margin: 0; */
}

@media (max-width: 768px) {
  .section {
  margin-bottom: 100px;
}
  .section-title-top {
    font-size: 60px;
  }
  .section-title-bottom {
  font-size: 40px;
  }
  .title-separator {
    height: 2px
  }
}
</style>
