<template>
  <Header-view :activePage="activePage" :headerHeight="headerHeight" />
  <div class="text-container">
    <h1>Datenschutzerklärung</h1>
    <h2>1. Einleitung</h2>
    <p>
      Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen. Mit dieser
      Datenschutzerklärung informieren wir Sie darüber, welche Daten auf dieser Webseite erhoben und
      wie diese verarbeitet werden. Diese Webseite dient dazu, die
      Softwareentwicklungsdienstleistungen eines Freelancers vorzustellen und bietet Ihnen die
      Möglichkeit, über ein Kontaktformular mit uns in Verbindung zu treten.
    </p>

    <h2>2. Verantwortlicher</h2>
    <p>
      Verantwortlich für die Datenverarbeitung auf dieser Webseite ist: <br /><br />
      Benjamin Heer <br />
      Kurzenmoor 38 <br />
      25370 Seester <br />
      Deutschland <br />
      Telefon: +49 160 6127316 <br />
      E-Mail: info@bestcellardesign.de
    </p>

    <h2>3. Erhebung und Verarbeitung personenbezogener Daten</h2>
    <p>
      Personenbezogene Daten werden ausschließlich dann erhoben, wenn Sie uns diese im Rahmen des
      Kontaktformulars freiwillig mitteilen. Hierzu zählen insbesondere Ihr Name, Ihre
      E-Mail-Adresse sowie weitere von Ihnen bereitgestellte Angaben, sofern diese für die
      Bearbeitung Ihrer Anfrage erforderlich sind. Eine darüber hinausgehende Erhebung oder
      Verarbeitung personenbezogener Daten findet nicht statt.
    </p>

    <h2>4. Cookies und Tracking-Technologien</h2>
    <p>Unsere Webseite setzt grundsätzlich keine Cookies ein. Bitte beachten Sie jedoch:</p>
    <ul>
      <li>
        Serverseitige Prozesse, wie beispielsweise das Session-Management, können in Ausnahmefällen
        Cookies (z.B. Session-Cookies) erzeugen.
      </li>
      <li>
        Ein selbstgehostetes Analyse-Tool (Umami) wird eingesetzt, um die Nutzung der Webseite
        anonymisiert auszuwerten. Hierbei werden keine personenbezogenen Daten an Dritte
        übermittelt.
      </li>
    </ul>

    <h2>5. Einbindung externer Tools und Frameworks</h2>
    <ul>
      <li>
        <b>Friendly Captcha:</b> Zum Schutz vor Spam im Kontaktformular wird Friendly Captcha
        eingesetzt. Bitte entnehmen Sie weitere Informationen hierzu der Datenschutzerklärung von
        Friendly Captcha.
      </li>
      <li>
        <b>Swiper Framework:</b> Für die ansprechende Darstellung verschiedener Komponenten wird das
        Swiper-Framework (nur die minifizierten Dateien .min.js und .min.css) eingebunden. Diese
        Einbindung dient ausschließlich der technischen Darstellung und führt nicht zu einer
        Übermittlung personenbezogener Daten.
      </li>
      <li>
        <b>AWS SES:</b> Für den Versand der über das Kontaktformular übermittelten Daten –
        namentlich Name, E-Mail-Adresse und Nachricht – wird der AWS Simple Email Service (AWS SES)
        genutzt. Die Daten werden hierbei ausschließlich zum Versand der E-Mail verwendet und nicht
        dauerhaft gespeichert, sofern keine zusätzliche Speicherung konfiguriert wurde. Es kann im
        Rahmen interner Prozesse zu einer temporären Speicherung in Logdateien kommen, die jedoch
        nur für einen begrenzten Zeitraum zu Zwecken der Fehlerbehebung oder
        Zustellungsprotokollierung erfolgt. Da die Datenverarbeitung in der AWS-Region Europe
        stattfindet, erfolgt eine Übermittlung in Drittländer grundsätzlich nicht, sofern keine
        gesetzlichen Verpflichtungen bestehen.
      </li>
    </ul>

    <h2>6. Datenübermittlung in Drittländer</h2>
    <p>
      Es findet keine Übermittlung personenbezogener Daten in Länder außerhalb der Europäischen
      Union bzw. des Europäischen Wirtschaftsraums statt.
    </p>

    <h2>7. Speicherdauer und Löschkonzept</h2>
    <p>
      Die über das Kontaktformular erhobenen personenbezogenen Daten werden ausschließlich zur
      Bearbeitung Ihrer Anfrage gespeichert. Sobald der Zweck der Speicherung entfällt – in der
      Regel nach Abschluss der Bearbeitung Ihrer Anfrage – werden Ihre Daten routinemäßig gelöscht
      oder anonymisiert, sofern keine gesetzlichen Aufbewahrungsfristen entgegenstehen.
    </p>

    <h2>8. Technische und organisatorische Maßnahmen</h2>
    <p>
      Zum Schutz Ihrer Daten haben wir angemessene technische und organisatorische Maßnahmen
      implementiert. Diese umfassen unter anderem den Einsatz von SSL-Verschlüsselung, regelmäßige
      Sicherheitsupdates, Firewalls und weitere Maßnahmen, die den heutigen Sicherheitsstandards
      entsprechen.
    </p>

    <h2>9. Rechte der betroffenen Personen</h2>
    <p>Sie haben das Recht:</p>
    <ul>
      <li>auf Auskunft über die zu Ihrer Person gespeicherten Daten,</li>
      <li>auf Berichtigung unrichtiger Daten,</li>
      <li>auf Löschung bzw. Einschränkung der Verarbeitung,</li>
      <li>auf Widerspruch gegen die Verarbeitung sowie</li>
      <li>auf Datenübertragbarkeit.</li>
    </ul>
    <p>
      Zur Ausübung dieser Rechte wenden Sie sich bitte an die in Punkt 2 genannten Kontaktdaten.
    </p>
    <h2>10. Bestellung eines Datenschutzbeauftragten</h2>
    <p>
      Für diese Webseite wurde kein Datenschutzbeauftragter bestellt, da dies gemäß den geltenden
      gesetzlichen Bestimmungen nicht erforderlich ist.
    </p>

    <h2>11. Änderungen dieser Datenschutzerklärung</h2>
    <p>
      Wir behalten uns vor, diese Datenschutzerklärung gelegentlich zu aktualisieren, um sie stets
      den aktuellen gesetzlichen Anforderungen anzupassen. Die jeweils aktuelle Version finden Sie
      auf unserer Webseite.
    </p>
    <br />
    <p>Stand: 01.01.2025</p>
  </div>
  <FooterView />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import HeaderView from '../components/HeaderView.vue';
import FooterView from '../components/FooterView.vue';

const { appContext } = getCurrentInstance();
const bus = appContext.config.globalProperties.$bus;
const router = useRouter();
const activePage = ref(0);
const headerHeight = ref(160);

function checkIfMobile() {
  if (window.innerWidth < 768) {
    headerHeight.value = 100;
  }
}

bus.$on('logoTapped', () => {
  router.push({ path: '/', hash: '#home' });
});

onMounted(() => {
  checkIfMobile();
  const header = document.querySelector('.header');
  const sep = document.querySelector('.sep');
  if (header && sep) {
    header.classList.add('background');
    sep.classList.add('fade-in');
  }
});
</script>

<style scoped>
.text-container {
  max-width: 1000px;
  margin-top: 180px;
  margin-left: auto;
  margin-right: auto;
  /* margin: auto; */
  color: var(--bcd-gray);
  padding: 20px;
}

h1,
h2,
h3,
p,
ul,
li {
  color: var(--bcd-gray);
}

@media (max-width: 768px) {
  .text-container {
    margin-top: 100px;
  }
}
</style>
