<template>
  <div class="swiper" ref="swiperContainer">
    <div class="swiper-wrapper">
      <TestimonialCard
        v-for="slide in testimonialItems"
        :image-path="slide.mockup_path"
        :title="slide.name"
        :description="slide.short_description"
        :feature-desc="slide.features_description"
        :app-store-url="slide.app_store_url"
        :play-store-url="slide.play_store_url"
        :features="slide.features"
        :key="slide.name"
        class="swiper-slide"
      />
    </div>
    <div class="swiper-button-next swiper-nav-btn"></div>
    <div class="swiper-button-prev swiper-nav-btn"></div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import TestimonialCard from './TestimonialCard.vue';

const props = defineProps({
  testimonialItems: {
    type: Array,
    required: true,
  },
});

const testimonialItems = ref(props.testimonialItems);

class CardView {
  constructor(container) {
    this.container = container;
    this.init();
    this.setupIntersectionObserver();
  }

  init() {
    this.currentPosition = 0;
    this.isVisible = false;
    this.animationFrame = null;
  }

  setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.container.classList.add('visible');
          this.isVisible = true;
        } else {
          this.container.classList.remove('visible');
          this.isVisible = false;
        }
      });
    }, options);

    observer.observe(this.container);
  }
}

const cardViewInstance = ref(null);
const cardSwiper = ref(null);
const swiperContainer = ref(null);

onMounted(() => {
  const container = document.querySelector('.slide-container-card');
  if (container) {
    cardViewInstance.value = new CardView(container);
  }
  if (swiperContainer.value) {
    cardSwiper.value = new window.Swiper(swiperContainer.value, {
      slidesPerView: 'auto',
      spaceBetween: 180,
      centeredSlides: true,
      loop: true,
      grabCursor: true,
      //   initialSlide: 0,
      //   loopAdditionalSlides: 3,
      effect: 'coverflow',
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 200,
        modifier: 1.5,
        // slideShadows: true,
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
      },
    });
  }
});
</script>

<style scoped>
.swiper {
  max-width: 1200px;
  padding: 50px 0;
  /* border: 1px solid red ; */
}

.swiper-slide {
  width: 800px;
  height: 630px;
  border-radius: 25px;
  /* border: 1px solid red ; */
}

.swiper-slide::before, .swiper-slide::after {
   border-radius: inherit;
}

/* .swiper-slide-shadow-coverflow {
  opacity: 0 !important;
}

.swiper-slide-shadow-right {
  opacity: 0 !important;
} */

/* .swiper-slide-next {
  opacity: 0 !important;
}

.swiper-slide-visible {
  opacity: 0 !important;
} */

.swiper-nav-btn {
  color: var(--bcd-orange);
  transition: color 0.5s ease;
}

.swiper-nav-btn:hover {
  color: var(--bcd-blue);
}

.swiper-nav-btn::before,
.swiper-nav-btn::after {
  font-size: 30px;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}
</style>
