<template>
  <header class="header" :style="headerStyle">
    <div v-if="!isMobile">
      <navbar-view class="nav-center-container" :activePage="activePage"> </navbar-view>
    </div>
    <div v-else style="width: 100%;">
      <button class="burger-button" @click="$bus.$emit('mobileMenuTapped')">≡</button>
    </div>
    <button class="logo-container" @click="$bus.$emit('logoTapped')">
      <img src="/assets/bcd_logo/bcd_logo_small_v2.webp" alt="Logo" loading="lazy" />
    </button>
    <div class="sep"></div>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import NavbarView from './NavbarView.vue';

const props = defineProps({
  activePage: Number,
  headerHeight: Number,
  isMobile: Boolean,
});

const headerStyle = computed(() => ({
  height: `${props.headerHeight}px`,
}));

</script>

<style scoped>
.header {
  /* width: 100vw; */
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}

.header.background {
  background-image: linear-gradient(90deg, rgb(0, 0, 0, 0.9), rgb(18, 18, 18, 0.9));
}

.logo-container {
  all: unset;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 1000;
  position: relative;
}

.logo-container img {
  max-height: 90%;
  /* width: 100%; */
  max-width: 90vw;
}

.nav-center-container {
  position: absolute;
  top: calc(50% - 25px);
  left: 0;
  right: 0;
  margin-inline: auto;
  width: fit-content;
  opacity: 0;
  z-index: 0;
  /* pointer-events: none; */
}

.sep {
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
  opacity: 0;
}

.burger-button {
  position: absolute;
  top: calc(50% - 20px);
  left: 0px;
  background: none;
  border: none;
  font-size: 40px;
  padding-left: 20px;
  /* font-size: 2rem; */
  cursor: pointer;
  color: var(--bcd-gray);
  opacity: 0;
  /* transition: opacity 1s ease; */
}

.fade-in {
  animation: fadeIn 1s;
  opacity: 1;
}

.fade-out {
  animation: fadeOut 0.5s;
  opacity: 0;
}

/* @media (max-width: 1024px) {
  .logo-container img {
    max-width: 924px;
  }
} */

/* .burger-button.visible {
  opacity: 1;
  animation: fade-in;
} */
</style>
