<template>
  <button class="blur" @click="$bus.$emit('blurTapped')"></button>
  <HeaderView :activePage="activePage" :headerHeight="headerHeight" :isMobile="isMobile" />
  <div v-if="!isMobile">
    <SideMenu />
  </div>
  <div v-else>
    <MobileMenuView />
    <MobileContactView />
  </div>
  <ContentView :isMobile="isMobile" />
  <FooterView />
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { ref, onMounted, onBeforeUnmount, watch, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import HeaderView from '../components/HeaderView.vue';
import SideMenu from '../components/SideMenu.vue';
import MobileMenuView from '../components/MobileMenuView.vue';
import ContentView from '../components/ContentView.vue';
import FooterView from '../components/FooterView.vue';
import MobileContactView from '../components/MobileContactView.vue';

const { appContext } = getCurrentInstance();
const bus = appContext.config.globalProperties.$bus;

const activePage = ref(0);
const headerMinHeight = ref(160);
const headerMaxHeight = ref(800);
const headerHeight = ref(800);
const headerScrollHeight = ref(640);
const listenToScroll = ref(true);
const menuVisible = ref(false);
const menuExtended = ref(false);
const mobileMenuExtended = ref(false);
const mobileContactExtended = ref(false);
const scrollOffsetMenuExtended = ref(0);
const scrollY = ref(0);
const isMobile = ref(false);
const router = useRouter();

let lastOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

function checkIfMobile() {
  // console.log('check');
  isMobile.value = window.innerWidth < 768;
  // console.log(window.innerWidth);
  // console.log(isMobile.value);
  if (isMobile.value) {
    headerHeight.value = 200;
    headerMaxHeight.value = 200;
  }
}

function updateHeaderHeight() {
  const screenHeight = window.innerHeight;
  // console.log(screenHeight);
  if (screenHeight >= 1180) {
    headerMinHeight.value = 160;
  } else if (screenHeight >= 1024) {
    headerMinHeight.value = 130;
  } else {
    headerMinHeight.value = 100;
  }
}

function getHeaderScrollHeight() {
  // console.log('get Header');
  // console.log(window.innerWidth);
  checkIfMobile();
  updateHeaderHeight();
  // console.log(headerMaxHeight.value - headerMinHeight.value);
  headerScrollHeight.value = headerMaxHeight.value - headerMinHeight.value;
  // console.log(`Headerscrollheight: ${headerScrollHeight.value}`);
}

function handleResize() {
  // Bestimme aktuelle Orientierung
  const newOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

  if (newOrientation !== lastOrientation) {
    lastOrientation = newOrientation;
    setTimeout(() => {
      getHeaderScrollHeight();
    }, 200);
  }
}

function activateLink(title, index) {
  const navContainer = document.getElementById(`${title}-link`);
  const animationDiv = document.querySelector('.animation');
  if (animationDiv && navContainer) {
    listenToScroll.value = false;

    const offset = navContainer.offsetLeft
    + (navContainer.offsetWidth - animationDiv.offsetWidth) / 2;

    if ((index > 1 && activePage.value < 2) || (index < 2 && activePage.value > 1)) {
      animationDiv.classList.add('fade-out-in-animation');
    }

    if (activePage.value !== index) {
      animationDiv.style.left = `${offset}px`;
      activePage.value = index;
    }
  }

  setTimeout(() => {
    listenToScroll.value = true;
    if (animationDiv) {
      animationDiv.classList.remove('fade-out-in-animation');
    }
  }, 1000);
}

function activateLinkOnScroll(scrollPos) {
  if (!listenToScroll.value) return;

  const aboutDiv = document.getElementById('about');
  const appDiv = document.getElementById('app');
  const webDiv = document.getElementById('web');

  if (scrollPos + 280 > webDiv.offsetTop) {
    if (activePage.value !== 3) {
      activateLink('web', 3);
    }
  } else if (scrollPos + 280 > appDiv.offsetTop) {
    if (activePage.value !== 2) {
      activateLink('app', 2);
    }
  } else if (scrollPos + 280 > aboutDiv.offsetTop) {
    if (activePage.value !== 1) {
      activateLink('about', 1);
    }
  } else if (activePage.value !== 0) {
    activateLink('home', 0);
  }
}

function handleScroll() {
  scrollY.value = window.scrollY;
  // console.log(scrollY.value);

  if (menuExtended.value && !isMobile.value) {
    if (scrollOffsetMenuExtended.value === 0) {
      scrollOffsetMenuExtended.value = scrollY.value;
    }
    if (Math.abs(scrollOffsetMenuExtended.value - scrollY.value) > 100) {
      menuExtended.value = false;
      scrollOffsetMenuExtended.value = 0;
    }
  }

  if (!isMobile.value) {
    if (scrollY.value > 600 && !menuVisible.value) {
      menuVisible.value = true;
    } else if (scrollY.value < 600 && menuVisible.value) {
      menuVisible.value = false;
      if (menuExtended.value) {
        menuExtended.value = false;
      }
    }
  }

  if (!isMobile.value) {
    activateLinkOnScroll(scrollY.value);
  }

  const header = document.querySelector('.header');
  const navbar = document.querySelector('.nav-center-container');
  const burgerButton = document.querySelector('.burger-button');
  const sep = document.querySelector('.sep');

  if (scrollY.value < headerScrollHeight.value) {
    headerHeight.value = headerMaxHeight.value - scrollY.value;
    // console.log(headerHeight.value);
    if (header && header.classList.contains('background')) {
      header.classList.remove('background');
    }
    if (navbar && navbar.classList.contains('fade-in')) {
      navbar.classList.remove('fade-in');
    }
    if (burgerButton && burgerButton.classList.contains('fade-in')) {
      burgerButton.classList.remove('fade-in');
    }
    if (sep && sep.classList.contains('fade-in')) {
      sep.classList.remove('fade-in');
    }
  } else {
    headerHeight.value = headerMinHeight.value;
    if (header && !header.classList.contains('background')) {
      header.classList.add('background');
    }
    if (navbar && !navbar.classList.contains('fade-in') && !isMobile.value) {
      navbar.classList.add('fade-in');
    }
    if (burgerButton && !burgerButton.classList.contains('fade-in') && isMobile) {
      burgerButton.classList.add('fade-in');
    }
    if (sep && !sep.classList.contains('fade-in')) {
      sep.classList.add('fade-in');
    }
  }
}

onMounted(() => {
  bus.$on('blurTapped', () => {
    if (isMobile.value) {
      // mobileContactExtended.value = false;
      if (mobileContactExtended.value) {
        mobileContactExtended.value = false;
      } else {
        mobileMenuExtended.value = !mobileMenuExtended.value;
      }
    } else {
      // menuExtended.value = !menuExtended.value;
      menuExtended.value = false;
    }
  });

  bus.$on('messageSendWait', () => {
    mobileContactExtended.value = false;
  });

  bus.$on('mobileMenuLinkTapped', (section) => {
    mobileMenuExtended.value = false;
    if (section === 'imprint' || section === 'privacy') {
      setTimeout(() => {
        router.push(`/${section}`);
      }, 400);
    } else if (section === 'contact') {
      setTimeout(() => {
        mobileContactExtended.value = true;
      }, 400);
    } else {
      const titleDiv = document.getElementById(section);
      if (titleDiv) {
        window.scrollTo({
          top: titleDiv.offsetTop - 120,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  });

  bus.$on('mobileMenuTapped', () => {
    mobileMenuExtended.value = !mobileMenuExtended.value;
  });

  bus.$on('logoTapped', () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });

  bus.$on('navbarLinkActivated', (titleIndexObj) => {
    activateLink(titleIndexObj.title, titleIndexObj.index);
    const titleDiv = document.getElementById(titleIndexObj.title);
    if (titleDiv) {
      window.scrollTo({
        top: titleDiv.offsetTop - 200,
        left: 0,
        behavior: 'smooth',
      });
    }
  });

  bus.$on('menuTapped', () => {
    menuExtended.value = !menuExtended.value;
  });

  bus.$on('messageTapped', () => {
    const messageTab = document.getElementById('messageTab');
    if (messageTab) {
      messageTab.classList.add('active');
    }
    const phoneTab = document.getElementById('phoneTab');
    if (phoneTab) {
      phoneTab.classList.remove('active');
    }
  });

  bus.$on('phoneTapped', () => {
    const phoneTab = document.getElementById('phoneTab');
    if (phoneTab) {
      phoneTab.classList.add('active');
    }
    const messageTab = document.getElementById('messageTab');
    if (messageTab) {
      messageTab.classList.remove('active');
    }
  });

  bus.$on('contactTapped', () => {
    if (isMobile.value) {
      mobileContactExtended.value = true;
    } else {
      menuExtended.value = !menuExtended.value;
    }
  });

  bus.$on('heroSliderTapped', (link) => {
    if (link !== 'contact') {
      const titleDiv = document.getElementById(link);
      if (titleDiv) {
        const offset = isMobile.value ? 120 : 200;
        window.scrollTo({
          top: titleDiv.offsetTop - offset,
          left: 0,
          behavior: 'smooth',
        });
      }
    } else if (isMobile.value) {
      mobileContactExtended.value = true;
    } else {
      menuExtended.value = true;
    }
  });

  // checkIfMobile();
  // updateHeaderHeight();
  getHeaderScrollHeight();
  if (isMobile.value) {
    window.addEventListener('orientationchange', handleResize);
  } else {
    window.addEventListener('resize', getHeaderScrollHeight);
  }
  // window.addEventListener('resize', handleResize);
  // window.addEventListener('resize', checkIfMobile);
  // window.addEventListener('resize', updateHeaderHeight);
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  if (isMobile.value) {
    window.removeEventListener('orientationchange', handleResize);
  } else {
    window.removeEventListener('resize', getHeaderScrollHeight);
  }
  // window.removeEventListener('orientationchange', getHeaderScrollHeight);
  window.removeEventListener('resize', handleResize);
  // window.removeEventListener('resize', checkIfMobile);
  // window.removeEventListener('resize', updateHeaderHeight);
  window.removeEventListener('scroll', handleScroll);
});

watch(menuVisible, (newValue) => {
  const sideMenu = document.querySelector('.menu-container');
  if (sideMenu) {
    if (newValue) {
      sideMenu.classList.add('visible');
    } else {
      sideMenu.classList.remove('visible');
    }
  }
});

watch(menuExtended, (newValue) => {
  const slideMenu = document.querySelector('.menu-container');
  const blur = document.querySelector('.blur');
  if (slideMenu && blur) {
    if (newValue) {
      slideMenu.classList.add('open');
      blur.classList.add('activated');
    } else {
      slideMenu.classList.remove('open');
      blur.classList.remove('activated');
    }
  }
});

watch(mobileMenuExtended, (newValue) => {
  const mobileMenu = document.querySelector('.mobile-menu-container');
  // console.log(mobileMenu);
  const blur = document.querySelector('.blur');
  if (mobileMenu && blur) {
    if (newValue) {
      mobileMenu.classList.add('open');
      blur.classList.add('activated');
    } else {
      mobileMenu.classList.remove('open');
      blur.classList.remove('activated');
    }
  }
});

watch(mobileContactExtended, (newValue) => {
  const mobileContactContainer = document.querySelector('.mobile-contact-container');
  const blur = document.querySelector('.blur');
  if (mobileContactContainer && blur) {
    if (newValue) {
      mobileContactContainer.classList.add('open');
      blur.classList.add('activated');
      mobileContactExtended.value = true;
    } else {
      mobileContactContainer.classList.remove('open');
      blur.classList.remove('activated');
    }
  }
});
</script>

<!-- <script>
import HeaderView from '../components/HeaderView.vue';
import ContentView from '../components/ContentView.vue';
import SideMenu from '../components/SideMenu.vue';
import FooterView from '../components/FooterView.vue';

export default {
  components: {
    HeaderView,
    ContentView,
    SideMenu,
    FooterView,
  },
  data() {
    return {
      activePage: 0,
      headerMinHeight: 160,
      headerHeight: 800,
      listenToScroll: true,
      menuVisible: false,
      menuExtended: false,
      scrollOffsetMenuExtended: 0,
    };
  },
  created() {
    // this.activateLink();
    this.$bus.$on('logoTapped', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
    this.$bus.$on('navbarLinkActivated', (titleIndexObj) => {
      this.activateLink(titleIndexObj.title, titleIndexObj.index);
      const titleDiv = document.getElementById(titleIndexObj.title);
      window.scrollTo({
        top: titleDiv.offsetTop - 200,
        left: 0,
        behavior: 'smooth',
      });
    });
    this.$bus.$on('menuTapped', () => {
      this.menuExtended = !this.menuExtended;
    });
    this.$bus.$on('messageTapped', () => {
      const messageTab = document.getElementById('messageTab');
      if (messageTab) {
        messageTab.classList.add('active');
      }
      const phoneTab = document.getElementById('phoneTab');
      if (phoneTab) {
        phoneTab.classList.remove('active');
      }
    });
    this.$bus.$on('phoneTapped', () => {
      const phoneTab = document.getElementById('phoneTab');
      if (phoneTab) {
        phoneTab.classList.add('active');
      }
      const messageTab = document.getElementById('messageTab');
      if (messageTab) {
        messageTab.classList.remove('active');
      }
    });
    this.$bus.$on('contactTapped', () => {
      this.menuExtended = !this.menuExtended;
    });
    this.$bus.$on('heroSliderTapped', (link) => {
      // console.log(link);
      if (link !== 'contact') {
        const titleDiv = document.getElementById(link);
        window.scrollTo({
          top: titleDiv.offsetTop - 200,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        this.menuExtended = true;
      }
    });
  },
  mounted() {
    this.updateHeaderHeight();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateHeaderHeight);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateHeaderHeight);
  },
  methods: {
    updateHeaderHeight() {
      const screenHeight = window.innerHeight;
      if (screenHeight > 1180) {
        this.headerMinHeight = 160;
      } else if (screenHeight >= 1024) {
        this.headerMinHeight = 130;
      } else {
        this.headerMinHeight = 100;
      }
      // if (screenHeight <= 1024) {
      //   this.headerMinHeight = 100;
      // } else if (screenHeight <= 1180) {
      //   this.headerMinHeight = 130;
      // } else {
      //   this.headerMinHeight = 160;
      // }
    },
    handleScroll() {
      if (this.menuExtended) {
        if (this.scrollOffsetMenuExtended === 0) {
          this.scrollOffsetMenuExtended = window.scrollY;
        }
        if (Math.abs(this.scrollOffsetMenuExtended - window.scrollY) > 100) {
          this.menuExtended = false;
          this.scrollOffsetMenuExtended = 0;
        }
      }
      this.scrollY = window.scrollY;
      if (this.scrollY > 600 && !this.menuVisible) {
        this.menuVisible = true;
      } else if (this.scrollY < 600 && this.menuVisible) {
        this.menuVisible = false;
        if (this.menuExtended) {
          this.menuExtended = false;
        }
      }

      this.activateLinkOnScroll(this.scrollY);
      const header = document.querySelector('.header');
      const navbar = document.querySelector('.nav-center-container');
      const sep = document.querySelector('.sep');
      if (this.scrollY < 640) {
        this.headerHeight = 800 - this.scrollY;
        if (navbar.classList.contains('fade-in')) {
          navbar.classList.remove('fade-in');
          navbar.classList.add('fade-out');
          sep.classList.remove('fade-in');
          // sep.classList.add('fade-out');
          header.classList.remove('background');
        }
      } else {
        this.headerHeight = this.headerMinHeight;
        header.classList.add('background');
        navbar.classList.remove('fade-out');
        navbar.classList.add('fade-in');
        sep.classList.remove('fade-out');
        sep.classList.add('fade-in');
      }
    },
    activateLink(title, index) {
      const navContainer = document.getElementById(`${title}-link`);
      // console.log(navContainer.offsetWidth);
      const animationDiv = document.querySelector('.animation');
      if (animationDiv && navContainer) {
        this.listenToScroll = false;
        const offset = navContainer.offsetLeft
        + (navContainer.offsetWidth - animationDiv.offsetWidth) / 2;

        if ((index > 1 && this.activePage < 2) || (index < 2 && this.activePage > 1)) {
          animationDiv.classList.add('fade-out-in-animation');
        }

        if (this.activePage !== index) {
          animationDiv.style.left = `${offset}px`;
          this.activePage = index;
        }
      }
      setTimeout(() => {
        this.listenToScroll = true;
        animationDiv.classList.remove('fade-out-in-animation');
      }, 1000);
    },
    activateLinkOnScroll(scrollY) {
      if (!this.listenToScroll) {
        return;
      }
      const aboutDiv = document.getElementById('about');
      const appDiv = document.getElementById('app');
      const webDiv = document.getElementById('web');
      if (scrollY + 200 > webDiv.offsetTop) {
        if (this.activePage !== 3) {
          this.activateLink('web', 3);
        }
      } else if (scrollY + 200 > appDiv.offsetTop) {
        if (this.activePage !== 2) {
          this.activateLink('app', 2);
        }
      } else if (scrollY + 200 > aboutDiv.offsetTop) {
        if (this.activePage !== 1) {
          this.activateLink('about', 1);
        }
      } else if (this.activePage !== 0) {
        this.activateLink('home', 0);
      }
    },
  },
  watch: {
    menuVisible(newValue) {
      const sideMenu = document.querySelector('.menu-container');
      if (sideMenu) {
        if (newValue) {
          sideMenu.classList.add('visible');
        } else {
          sideMenu.classList.remove('visible');
        }
      }
    },
    menuExtended(newValue) {
      const slideMenu = document.querySelector('.menu-container');
      // const menuLogo = document.querySelector('.menu-logo-button');
      const blur = document.querySelector('.blur');
      if (newValue) {
        slideMenu.classList.add('open');
        // menuLogo.classList.add('open');
        blur.classList.add('activated');
      } else {
        slideMenu.classList.remove('open');
        // menuLogo.classList.remove('open');
        blur.classList.remove('activated');
      }
    },
  },
};
</script> -->
