<template>
  <div class="mobile-contact-container">
    <div class="inner-container">
      <div class="contact-form-container">
        <h1 class="contact-title">Ich freue mich auf Deine Anfrage!</h1>
        <form id="contactForm" @submit.prevent="sendForm">
          <div class="form-group">
            <label for="name"
              >Name:
              <input type="text" id="name" name="name" v-model="formData.name" required />
            </label>
          </div>
          <div class="form-group">
            <label for="email"
              >E-Mail:
              <input type="email" id="email" name="email" v-model="formData.email" required />
            </label>
          </div>
          <div class="form-group">
            <label for="message"
              >Nachricht:
              <textarea id="message" name="message" v-model="formData.message" required></textarea>
            </label>
          </div>
          <div style="display: none">
            <label for="nothing"
              >Bitte nicht ausfüllen!
              <input v-model="hpfield" name="nothing" id="nothing" />
            </label>
          </div>
          <button type="submit">Nachricht senden</button>
        </form>
      </div>
      <div class="phone-info">
        <p style="font-size: 14px; color: var(--bcd-gray)">
          Oder ruf mich gerne an / schreib mir bei WhatsApp:</p>
        <p>
          <a
            href="tel:+4916061274316"
            style="
              text-decoration: none;
              color: white;
              font-size: 18px;
              padding-bottom: 5px;
            "
            >Mobil: +49 160 6127316</a
          >
        </p>
        <p>
          <a
            href="mailto:info@bestcellardesign.de"
            style="text-decoration: none; color: white; font-size: 18px"
            >Email: info@bestcellardesign.de</a
          >
        </p>
        <div style="height: 0px"></div>
        <p style="font-size: 14px; color: var(--bcd-gray)">
          In der Regel werde ich Dir bei schriftlichen Anfragen innerhalb von 2 Werktagen antworten!
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';

const formData = ref({
  name: '',
  email: '',
  message: '',
});
const hpfield = ref('');

const successMessage = ref('');
const errorMessage = ref('');

const { appContext } = getCurrentInstance();
const bus = appContext.config.globalProperties.$bus;

const apiUrl = 'https://0xt2lqq2e6.execute-api.eu-north-1.amazonaws.com/prod/ses-send-email';

async function sendForm() {
  bus.$emit('messageSendWait');

  if (hpfield.value.trim() !== '') {
    alert('Bot-Verdacht!');
    return;
  }

  successMessage.value = '';
  errorMessage.value = '';

  try {
    const bodyData = {
      name: formData.value.name,
      email: formData.value.email,
      message: formData.value.message,
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      throw new Error(errorResponse || 'Unbekannter Fehler vom Server');
    }

    await response.json();
    successMessage.value = 'Nachricht wurde erfolgreich gesendet!';
    formData.value = { name: '', email: '', message: '' };
  } catch (err) {
    console.error('SendForm error:', err);
    errorMessage.value = 'Fehler beim Senden. Bitte versuche es erneut.';
  }
}
</script>

<style scoped>
.mobile-contact-container {
    position: fixed;
    width: 95%;
    height: 90%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    margin: 0 auto;
    border-radius: 20px 20px 0 0;
    border-top: 2px solid var(--border-color);
    border-left: 2px solid var(--border-color);
    border-right: 2px solid var(--border-color);
    /* padding: 40px; */
    background-image: linear-gradient(90deg, rgb(0, 0, 0), rgb(18, 18, 18));
    /* background-color: aquamarine; */
    transform: translateY(100%);
    transition: transform 0.5s ease;
}

.mobile-contact-container.open {
    transform: translateY(0px);
}

.inner-container {
    display: flexbox;
}

.contact-form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.contact-title {
    font-size: 20px;
    color: var(--bcd-gray);
}

.form-group label {
  color: var(--bcd-gray);
  /* display: block; */
  /* padding-bottom: 5px; */
  /* color: #ffffff; */
}

.form-group input, textarea {
  font-size: 16px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  background-color: #2c2c2c;
  border: 1px solid #4c4c4c;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3498db;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

button[type='submit'] {
  /* position: absolute; */
  /* left: 50%; */
  width: 200px;
  padding: 10px;
  background-color: var(--bcd-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button[type='submit']:hover {
  background-color: var(--bcd-orange);
}

.phone-info {
  color: #eee;
  padding-left: 20px;
  padding-right: 20px;
  /* padding: 20px; */
}
</style>
