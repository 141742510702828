import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Imprint from '../views/ImprintView.vue';
import Privacy from '../views/PrivacyView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
    meta: { headerType: 'small' }, // Meta data for header customization
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: { headerType: 'small' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth', top: '200' };
    }
    return { top: 0, behavior: 'smooth' };
  },
});

export default router;
