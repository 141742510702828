<template>
  <div class="slide-container-hero swiper">
    <div class="slide-content-hero">
      <div class="card-wrapper swiper-wrapper">
        <HeroSliderCard
          v-for="(card, index) in cards"
          class="hero-card swiper-slide"
          :key="index"
          :title="card.title"
          :desc="card.desc"
          :image-path="card.imagePath"
          :link="card.link"
        />
      </div>
      <div class="swiper-button-next swiper-nav-btn"></div>
      <div class="swiper-button-prev swiper-nav-btn"></div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import HeroSliderCard from './HeroSliderCard.vue';

class HeroSlider {
  constructor(container) {
    this.container = container;
    this.init();
    this.setupIntersectionObserver();
  }

  init() {
    this.currentPosition = 0;
    this.isVisible = false;
    this.animationFrame = null;
  }

  setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.container.classList.add('visible');
          this.isVisible = true;
        } else {
          this.container.classList.remove('visible');
          this.isVisible = false;
        }
      });
    }, options);

    observer.observe(this.container);
  }
}

const cards = [
  {
    title: 'App Development',
    desc: 'Native oder Hybrid? Ich erstelle performante iOS- und Android-Apps, maßgeschneidert für Dein Unternehmen.',
    imagePath: '/assets/hero_slider_images/demo2_small.webp',
    link: 'app',
  },
  {
    title: 'Web Solutions',
    desc: 'Ob moderne Single-Page-App oder WordPress-Website – ich liefere schnelle, responsive Weblösungen.',
    imagePath: '/assets/hero_slider_images/demo1_small.webp',
    link: 'web',
  },
  {
    title: 'Beratung & Support',
    desc: 'Von der Konzeptentwicklung bis zur Wartung – ich stehe Dir mit Rat und Tat zur Seite.',
    imagePath: '/assets/hero_slider_images/demo3_small.webp',
    link: 'contact',
  },
];

// let cardViewInstance;
const cardViewInstance = ref(null);
const heroSwiper = ref(null);

onMounted(() => {
  const container = document.querySelector('.slide-container-hero');
  if (container) {
    cardViewInstance.value = new HeroSlider(container);
  }
  heroSwiper.value = new window.Swiper('.slide-content-hero', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    effect: 'slide',
    // autoHeight: true,
    // grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    touchEventsTarget: 'wrapper',
    touchRatio: 1,
    touchAngle: 45,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    preventClicks: false,
  });
});
</script>

<style scoped>
.slide-container-hero {
  margin: 50px auto;
  max-width: 820px;
  height: 420px;
  opacity: 0;
  transition: opacity 1s ease;
}

.slide-container-hero.visible {
  opacity: 1;
}

.slide-container-hero .swiper-slide {
  height: 360px;
}

.slide-content-hero {
  margin: 0 40px;
  padding: 15px;
  overflow: hidden;
  border-radius: 25px;
}

.hero-card {
  border-radius: 25px;
  transition: transform 0.3s ease;
}

.swiper-nav-btn {
  color: var(--bcd-orange);
  transition: color 0.5s ease;
}

.swiper-nav-btn:hover {
  color: var(--bcd-blue);
}

.swiper-nav-btn::before,
.swiper-nav-btn::after {
  font-size: 30px;
}

.swiper-button-next {
  right: 0;
  /* opacity: 0; */
}

.swiper-button-prev {
  left: 0;
  /* opacity: 0; */
}

@media (max-width: 768px) {
  .slide-content-hero {
    margin: 0 10px;
    padding: 10px;
  }
  .swiper-button-next {
    opacity: 0;
  }

  .swiper-button-prev {
    opacity: 0;
  }
}
</style>
