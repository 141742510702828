<template>
  <div class="horizontal-slider-container">
    <div class="horizontal-image-slider"></div>
  </div>
</template>

<script>
class HorizontalImageSlider {
  constructor(container, images) {
    this.container = container;
    this.images = images;
    this.init();
    this.setupIntersectionObserver();
  }

  init() {
    // Slider erstellen
    this.slider = document.createElement('div');
    this.slider.className = 'horizontal-slider';

    // Bilder dreimal hinzufügen für nahtloses Scrollen
    [...this.images, ...this.images, ...this.images].forEach((src) => {
      const imgContainer = document.createElement('div');
      imgContainer.className = 'horizontal-slider-item';

      const img = document.createElement('img');
      img.src = src;
      img.alt = 'Slider Image';

      imgContainer.appendChild(img);
      this.slider.appendChild(imgContainer);
    });

    this.container.appendChild(this.slider);

    // Animation vorbereiten
    this.currentPosition = 0;
    this.isVisible = false;
    this.animationFrame = null;
  }

  setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.container.parentElement.classList.add('visible');
          this.isVisible = true;
          if (!this.animationFrame) {
            this.startAnimation();
          }
        } else {
          this.container.parentElement.classList.remove('visible');
          this.isVisible = false;
        }
      });
    }, options);

    observer.observe(this.container);
  }

  startAnimation() {
    const animate = () => {
      if (!this.isVisible) {
        this.animationFrame = null;
        return;
      }

      this.currentPosition -= 0.5;
      this.slider.style.transform = `translateX(${this.currentPosition}px)`;

      // Wenn ein Bild komplett durchgelaufen ist, setze die Position zurück
      const imageWidth = 320; // Bildbreite + Padding
      const totalImages = this.images.length;

      // Wenn wir ein Drittel der Gesamtbreite durchlaufen haben,
      // springen wir zurück zum mittleren Drittel
      if (Math.abs(this.currentPosition) >= imageWidth * totalImages) {
        this.currentPosition += imageWidth * totalImages;
      }

      this.animationFrame = requestAnimationFrame(animate);
    };

    this.animationFrame = requestAnimationFrame(animate);
  }
}

export default {
  name: 'HorizontalImageSlider',
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    const container = this.$el.querySelector('.horizontal-image-slider');
    if (container) {
      this.sliderInstance = new HorizontalImageSlider(container, this.images);
    }
    // this.$nextTick(() => {
    // });
  },
  beforeUnmount() {
    if (this.sliderInstance && this.sliderInstance.animationFrame) {
      cancelAnimationFrame(this.sliderInstance.animationFrame); // Animation stoppen
    }
  },
};
</script>

<style>
.spacer {
  height: 80vh;
}

.horizontal-slider-container {
  /* width: 1440px; */
  width: 100%;
  /* max-width: var(--body-width); */
  height: var(--horizontal-slider-height);
  /* margin-left: calc(-50vw + 50%); */
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
  /* background-color: #00000000; */
  overflow: hidden;
  margin: auto;
  margin-top: 50px;
}

.horizontal-slider-container.visible {
  opacity: 1;
}

.horizontal-slider-container::before,
.horizontal-slider-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 150px;
  z-index: 2;
  pointer-events: none;
}

.horizontal-slider-container::before {
  left: 0;
  background: linear-gradient(to right, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
}

.horizontal-slider-container::after {
  right: 0;
  background: linear-gradient(to left, rgb(19, 19, 19) 50%, rgba(26, 26, 26, 0) 100%);
}

.horizontal-image-slider {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.horizontal-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  height: 100%;
  left: 0;
  transition: transform 0.1s linear;
}

.horizontal-slider-item {
  flex: 0 0 auto;
  height: var(--horizontal-slider-height);
  padding: 10px;
  /* flex: 0 0 auto; */
  width: 200px;
}

.horizontal-slider-item img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  filter: grayscale(100%);
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.horizontal-slider-item:hover img {
  opacity: 1;
  filter: none;
}

@media (max-width: 768px) {
  .horizontal-slider-item {
    height: 40px;
    width: 100px;
  }
  .horizontal-slider-container::before,
  .horizontal-slider-container::after {
    width: 10px;
  }
}
</style>
